<template>
  <div>
    <div class="px-6 py-8">
    <div class="container flex justify-between mx-auto">
      <div class="w-full lg:w-8/12">
        <!-- Title -->
        <div class="max-w-4xl px-6 py-3 mx-auto bg-white rounded-lg shadow-md">
          <div class="flex items-center justify-between">
            <span class="font-bold text-gray-800 text-2xl">My Profile</span>
          </div>
        </div>
        <!-- About -->
        <div class="mt-6">
          <div class="max-w-4xl px-6 py-3 mx-auto bg-white rounded-lg shadow-md">
            <div class="flex items-center justify-between">
              <!-- <span class="font-light text-gray-700 text-xl">About</span> -->
              <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span clas="text-green-500">
                  <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </span>
                <span class="font-light text-gray-700 text-xl tracking-wide">About</span>
              </div>
              <a href="#" class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">Edit</a>
            </div>
            <hr class="mt-2">
            <div class="text-gray-700">
              <div class="grid md:grid-cols-2 text-sm">
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">First Name</div>
                  <div class="px-4 py-2">Jane</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Last Name</div>
                  <div class="px-4 py-2">Doe</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Gender</div>
                  <div class="px-4 py-2">Female</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Contact No.</div>
                  <div class="px-4 py-2">+11 998001001</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Current Address</div>
                  <div class="px-4 py-2">Beech Creek, PA, Pennsylvania</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Permanant Address</div>
                  <div class="px-4 py-2">Arlington Heights, IL, Illinois</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Email</div>
                  <div class="px-4 py-2">
                    <a class="text-blue-800" href="mailto:jane@example.com">{{ user.email }}</a>
                  </div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Birthday</div>
                  <div class="px-4 py-2">Feb 06, 1998</div>
                </div>
              </div>
            </div>
            <button class="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
              Show Full Information
            </button>
          </div>
        </div>

          <div class="mt-6">
              <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <div class="flex items-center justify-between"><span class="font-light text-gray-600">Feb 14,
                          2019</span><a href="#"
                          class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">PHP</a>
                  </div>
                  <div class="mt-2"><a href="#" class="text-2xl font-bold text-gray-700 hover:underline">PHP:
                          Array to Map</a>
                      <p class="mt-2 text-gray-600">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                          Tempora expedita dicta totam aspernatur doloremque. Excepturi iste iusto eos enim
                          reprehenderit nisi, accusamus delectus nihil quis facere in modi ratione libero!</p>
                  </div>
                  <div class="flex items-center justify-between mt-4"><a href="#"
                          class="text-blue-500 hover:underline">Read more</a>
                      <div><a href="#" class="flex items-center"><img
                                  src="https://images.unsplash.com/photo-1531251445707-1f000e1e87d0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=281&amp;q=80"
                                  alt="avatar" class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block">
                              <h1 class="font-bold text-gray-700 hover:underline">Lisa Way</h1>
                          </a></div>
                  </div>
              </div>
          </div>
          <div class="mt-6">
              <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <div class="flex items-center justify-between"><span class="font-light text-gray-600">Dec 23,
                          2018</span><a href="#"
                          class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">Django</a>
                  </div>
                  <div class="mt-2"><a href="#" class="text-2xl font-bold text-gray-700 hover:underline">Django
                          Dashboard - Learn by Coding</a>
                      <p class="mt-2 text-gray-600">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                          Tempora expedita dicta totam aspernatur doloremque. Excepturi iste iusto eos enim
                          reprehenderit nisi, accusamus delectus nihil quis facere in modi ratione libero!</p>
                  </div>
                  <div class="flex items-center justify-between mt-4"><a href="#"
                          class="text-blue-500 hover:underline">Read more</a>
                      <div><a href="#" class="flex items-center"><img
                                  src="https://images.unsplash.com/photo-1500757810556-5d600d9b737d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=735&amp;q=80"
                                  alt="avatar" class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block">
                              <h1 class="font-bold text-gray-700 hover:underline">Steve Matt</h1>
                          </a></div>
                  </div>
              </div>
          </div>
          <div class="mt-6">
              <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <div class="flex items-center justify-between"><span class="font-light text-gray-600">Mar 10,
                          2018</span><a href="#"
                          class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">Testing</a>
                  </div>
                  <div class="mt-2"><a href="#" class="text-2xl font-bold text-gray-700 hover:underline">TDD
                          Frist</a>
                      <p class="mt-2 text-gray-600">Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                          Tempora expedita dicta totam aspernatur doloremque. Excepturi iste iusto eos enim
                          reprehenderit nisi, accusamus delectus nihil quis facere in modi ratione libero!</p>
                  </div>
                  <div class="flex items-center justify-between mt-4"><a href="#"
                          class="text-blue-500 hover:underline">Read more</a>
                      <div><a href="#" class="flex items-center"><img
                                  src="https://images.unsplash.com/photo-1502980426475-b83966705988?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=373&amp;q=80"
                                  alt="avatar" class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block">
                              <h1 class="font-bold text-gray-700 hover:underline">Khatab Wedaa</h1>
                          </a></div>
                  </div>
              </div>
          </div>
          <div class="mt-8">
              <div class="flex">
                  <a href="#" class="px-3 py-2 mx-1 font-medium text-gray-500 bg-white rounded-md cursor-not-allowed">
                      previous
                  </a>
              
                  <a href="#" class="px-3 py-2 mx-1 font-medium text-gray-700 bg-white rounded-md hover:bg-blue-500 hover:text-white">
                      1
                  </a>
              
                  <a href="#" class="px-3 py-2 mx-1 font-medium text-gray-700 bg-white rounded-md hover:bg-blue-500 hover:text-white">
                      2
                  </a>
              
                  <a href="#" class="px-3 py-2 mx-1 font-medium text-gray-700 bg-white rounded-md hover:bg-blue-500 hover:text-white">
                      3
                  </a>
              
                  <a href="#" class="px-3 py-2 mx-1 font-medium text-gray-700 bg-white rounded-md hover:bg-blue-500 hover:text-white">
                      Next
                  </a>
              </div>
          </div>
      </div>
      <div class="hidden w-4/12 -mx-8 lg:block">
        <div class="px-8">
          <div class="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white border-t-2 border-purple-300 rounded-lg shadow-md">
            <div class="bg-white p-3">
              <div class="image overflow-hidden">
                <img class="h-auto w-full mx-auto rounded-lg"
                    src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                    alt="">
              </div>
              <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">Jane Doe</h1>
              <h3 class="text-gray-600 font-lg text-semibold leading-6">Owner at Her Company Inc.</h3>
              <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur non deserunt
              </p>
              <ul class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                <li class="flex items-center py-3">
                  <span>Status</span>
                  <span class="ml-auto">
                    <span class="bg-green-500 py-1 px-2 rounded text-white text-sm">Active</span>
                  </span>
                </li>
                <li class="flex items-center py-3">
                  <span>Member since</span>
                  <span class="ml-auto">Nov 07, 2016</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
          <div class="px-8 mt-10">
              <h1 class="mb-4 text-xl font-bold text-gray-700">Categories</h1>
              <div class="flex flex-col max-w-sm px-4 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <ul>
                      <li><a href="#" class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">-
                              AWS</a></li>
                      <li class="mt-2"><a href="#"
                              class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">-
                              Laravel</a></li>
                      <li class="mt-2"><a href="#"
                              class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">- Vue</a>
                      </li>
                      <li class="mt-2"><a href="#"
                              class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">-
                              Design</a></li>
                      <li class="flex items-center mt-2"><a href="#"
                              class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">-
                              Django</a></li>
                      <li class="flex items-center mt-2"><a href="#"
                              class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">- PHP</a>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="px-8 mt-10">
              <h1 class="mb-4 text-xl font-bold text-gray-700">Recent Post</h1>
              <div class="flex flex-col max-w-sm px-8 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <div class="flex items-center justify-center"><a href="#"
                          class="px-2 py-1 text-sm text-green-100 bg-gray-600 rounded hover:bg-gray-500">Laravel</a>
                  </div>
                  <div class="mt-4"><a href="#" class="text-lg font-medium text-gray-700 hover:underline">Build
                          Your New Idea with Laravel Freamwork.</a></div>
                  <div class="flex items-center justify-between mt-4">
                      <div class="flex items-center"><img
                              src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=731&amp;q=80"
                              alt="avatar" class="object-cover w-8 h-8 rounded-full"><a href="#"
                              class="mx-3 text-sm text-gray-700 hover:underline">Alex John</a></div><span
                          class="text-sm font-light text-gray-600">Jun 1, 2020</span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>






    <!-- component -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css"> -->
    <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"> -->
    <section class=" py-1 bg-blueGray-50">
      <div class="w-full lg:w-8/12 px-4 mx-auto mt-6">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">
                My account
              </h6>
              <button class="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                Settings
              </button>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                User Information
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Username
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="lucky.jesse">
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Email address
                    </label>
                    <input type="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="jesse@example.com">
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      First Name
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="Lucky">
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Last Name
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="Jesse">
                  </div>
                </div>
              </div>

              <hr class="mt-6 border-b-1 border-blueGray-300">

              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Contact Information
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Address
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09">
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      City
                    </label>
                    <input type="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="New York">
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Country
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="United States">
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      Postal Code
                    </label>
                    <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value="Postal Code">
                  </div>
                </div>
              </div>

              <hr class="mt-6 border-b-1 border-blueGray-300">

              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                About Me
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                      About me
                    </label>
                    <textarea type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="4"> A beautiful UI Kit and Admin for JavaScript &amp; Tailwind CSS. It is Freeand Open Source.</textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'ProfileView',
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>